import { apiRoutes } from 'apiRoutes'
import { userTypes } from 'constants/index'
import { rootServiceApi } from 'store/service'
import { ChatsData } from 'utils/types/types'

const chatServices = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    getChannels: build.query<ChatsData, { profile_id?: string | undefined; userType: string | undefined }>({
      query: ({ profile_id, userType }) => ({
        method: 'GET',
        url: userType === userTypes.parent ? `${apiRoutes.chats.getParentChannels}${profile_id}/` : apiRoutes.chats.getOtherUsersChannels,
      }),
    }),
  }),
})

export const { useGetChannelsQuery } = chatServices
