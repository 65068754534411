// Constants
import { apiPath } from '../constants'
// Instruments
// import axios from 'axios'
import { fetchRequest } from './fetch'
import { request } from './axios'

export const chatApi = {
  getAllChannels: (data) => {
    return fetchRequest(`${apiPath}/api/chats/`, {
      method: 'GET',
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  getAllChannelsClient: (data) => {
    return fetchRequest(`${apiPath}/api/chats/profiles/${data.id}/`, {
      method: 'GET',
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  getChannelsByType: (data) => {
    return fetchRequest(`${apiPath}/api/chats/?user_type=${data.chatsType}/`, {
      method: 'GET',
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  archiveChat: (data) => {
    return fetchRequest(`${apiPath}/api/chats/${data.chatId}/delete/`, {
      method: 'DELETE',
    })
  },
  viewedChat: (data) => {
    return fetchRequest(`${apiPath}/api/chats/${data.chatId}/viewed/`, {
      method: 'PATCH',
      body: JSON.stringify({
        un_viewed_messages: data.un_viewed_messages,
      }),
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  getContactDetails: async (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/chats/${data.chatId}/contact/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  getChatAttachemnts: async (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/chats/${data.chatId}/attachments/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
}
