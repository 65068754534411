import { useAuth } from 'hooks/useAuth'
import { useContentBundle } from 'hooks/useContentBundle'
import { useEffect, useId, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAllChannels } from 'reducers/chatSlice'
import WebSocketService from 'websocket'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { userTypes } from 'constants/index'
import { useGetChannelsQuery } from './service'
import { useParams } from 'react-router-dom'
import { Chat } from 'utils/types/types'

interface Participant {
  user_id: number
  user_name: string
  user_type: string
  profile_name: string
  role: string
  profile_pic: string
}

interface LastMessage {
  text: string
  author: number
  created_at: string
}

export interface ChatRoom {
  chat_id: number
  room_id: string
  room_name: string | null
  participants: Participant[]
  unread_messages: number
  last_message: LastMessage
  grouped?: boolean
  rooms?: ChatRoom[]
  parent_name?: string
}
export const useChat = () => {
  const dispatch = useDispatch()
  const { profileId } = useParams()
  const [tab, setTab] = useState<string>('1')
  const [selectedProfileId, setSelectedProfileId] = useState<number | string>()
  const [searchTerm, setSearchTerm] = useState('')
  // const [channels, setChannels] = useState<Array<ChatRoom>>()
  // const chatSystem = useSelector((state: any) => state.chatSystem)
  const { user } = useAuth()
  const [webSocketInstance, setWebSocketInstance] = useState<WebSocketService>()
  const { data: channels, refetch: refetchChats } = useGetChannelsQuery(
    {
      profile_id: String(selectedProfileId ?? profileId),
      userType: user.user_type,
    },
    {
      skip: !user.user_type,
    },
  )
  /**
   * Updates channel information based on incoming notifications.
   * @param {Object} notification - The notification object received
   */
  const handleUpdateChatsInformation = (notification: any) => {
    let channelIndexToUpdate = 0
    let chatIndexToUpdate = 0

    channels?.forEach((channel, channelIndex) => {
      const foundChatIndex = channel?.chat_id === notification?.chat_id
      if (foundChatIndex) {
        channelIndexToUpdate = channelIndex
        chatIndexToUpdate = channelIndex
        return
      }
    })

    if (channelIndexToUpdate !== -1 && chatIndexToUpdate !== -1) {
      const newAllChannels = JSON.parse(JSON.stringify(channels))

      newAllChannels[channelIndexToUpdate].chats[chatIndexToUpdate] = {
        ...newAllChannels[channelIndexToUpdate].chats[chatIndexToUpdate],
        ...notification,
      }

      newAllChannels[channelIndexToUpdate].participant = {
        ...newAllChannels[channelIndexToUpdate].participant,
        unread_messages_for_all_chats: notification.unread_messages_for_all_chats,
      }

      dispatch(setAllChannels(newAllChannels))
    }

    // setPalyNotification(true)
  }

  /**
   * Initializes WebSocket connection for notifications and sets up callbacks.
   */
  useEffect(() => {
    if (user) {
      const webSocket = new WebSocketService('notifications', '0', user)

      if (channels?.length) {
        webSocket.setNotificationCallback((notification: any) => {
          handleUpdateChatsInformation(notification)
        })

        setWebSocketInstance(webSocket)
        // setIsNotificationInit(false)
      }
    }
    // setChannels(chatSystem?.allChannels ?? [])
  }, [JSON.stringify(channels)])

  /**
   * Connects WebSocket instance when initialized or updated.
   */
  useEffect(() => {
    if (webSocketInstance && webSocketInstance?.state() !== 1) {
      webSocketInstance.connect()
    }
  }, [webSocketInstance])

  const filterBySearchTerm = (channels: ChatRoom[], searchTerm: string) => {
    return channels.filter((channel) => {
      const roomNameMatch = channel.room_name?.toLowerCase().includes(searchTerm.toLowerCase())
      const participantProfileNameMatch = channel.participants.some((participant) => participant.profile_name.toLowerCase().includes(searchTerm.toLowerCase()))
      const participantUserNameMatch = channel.participants.some((participant) => participant.user_name.toLowerCase().includes(searchTerm.toLowerCase()))
      const roleMatch = channel.participants.some((participant) => participant.role.toLowerCase().includes(searchTerm.toLowerCase()))
      return roomNameMatch || participantProfileNameMatch || participantUserNameMatch || roleMatch
    })
  }

  const filteredChannels = useMemo(() => {
    let filtered: Array<ChatRoom> | undefined = []
    if (tab === '1') {
      if (searchTerm) {
        filtered = filterBySearchTerm(channels ?? [], searchTerm)
      } else {
        filtered = channels
      }
    } else {
      filtered = channels?.filter((channel) => channel.participants.some((participant) => JSON.parse(tab as string).includes(participant.role)))
      if (searchTerm) {
        filtered = filterBySearchTerm(filtered ?? [], searchTerm)
      }
    }

    const grouped = filtered?.reduce((acc: ChatRoom[], curr) => {
      if (curr.room_name) {
        acc.push(curr)
      } else {
        const parent = curr.participants.find((item) => item.role === userTypes.parent)
        const userId = parent?.user_id

        const existingChatIndex = acc.findIndex((chat) => !chat.room_name && chat.participants.some((p) => p.role === userTypes.parent && p.user_id === userId))

        if (existingChatIndex === -1) {
          // Create new entry without grouped flag initially
          acc.push({
            ...curr,
            parent_name: parent?.user_name,
            rooms: [curr],
          })
        } else {
          // Add to existing group and set grouped=true since we found multiple
          acc[existingChatIndex] = {
            ...acc[existingChatIndex],
            grouped: true,
            rooms: [...(acc[existingChatIndex].rooms || []), curr],
          }
        }
      }
      return acc
    }, [])

    return grouped
  }, [tab, channels, searchTerm])
  const { t } = useContentBundle({
    arabicContent: ar,
    englishContent: en,
    key: 'chat',
  })
  return {
    filteredChannels,
    webSocketInstance,
    user,
    tab,
    searchTerm,
    profileId,
    selectedProfileId,
    setSearchTerm,
    t,
    setTab,
    setSelectedProfileId,
    refetchChats,
  }
}
