export const courseEnglish = {
  reviews: 'Reviews',
  courseLessons: 'Course Lessons',
  completed: 'Completed',
  purchased: 'Purchased',
  download: 'Download',
  video: 'Video',
  mcq: 'MCQ',
  document: 'Document',
  documents: 'Documents',
  nextQuestion: 'Next Question',
  nextLesson: 'Next Lesson',
  stopAutoPlay: 'Stay here',
  explanation: 'Explanation',
  searchForCourse: 'Search for course',
  courses: 'Courses',
  course: 'Course',
  overview: 'Overview',
  learningObjective: 'Learning Objective',
  learningJourney: 'Learning Journey',
  quiz: 'Quiz',
  videoLesson: 'Video Lesson',
  readingMaterial: 'Reading Material',
  startNow: 'Start Now',
  enrollNow: 'Enroll Now',
  lessons: 'Lessons',
  lesson: 'Lesson',
  rateThisCourse: 'Rate this course',
  submitFeedback: 'Submit Feedback',
  courseFeedback: 'Course Feedback',
  comment: 'Comment',
  shareYourThoughts: 'Share your thoughts',
  author: 'Author',
  continue: 'Continue',
  description: 'Description',
  progress: 'Progress',
  finish: 'Finish',
  yearsOfExperience: 'Years of Experience',
  submitAnswer: 'Submit Answer',
  no_courses: 'Courses are not available yet!',
  mcqSubmitError: 'Error submitting answer, please try again!',
  courseAccessErrorTitle: 'Course Access Required',
  courseAccessErrorDescription: 'This premium content requires purchase. Unlock this course to access all materials and start learning today.',
  courseAccessErrorButton: 'Browse Other Courses',
}

export type CourseContentType = typeof courseEnglish
