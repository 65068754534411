export const courseArabic = {
  reviews: 'التقيم',
  courseLessons: 'دروس الدورة',
  completed: 'مكتمل',
  purchased: 'مشتراة',
  download: 'تحميل',
  video: 'فيديو',
  mcq: 'اختيار من متعدد',
  document: 'وثيقة',
  documents: 'وثائق',
  nextQuestion: 'السؤال التالي',
  nextLesson: 'الدرس التالي',
  stopAutoPlay: 'ابقَ هنا',
  explanation: 'تفسير',
  searchForCourse: 'ابحث عن دورة',
  courses: 'الدورات',
  course: 'دورة',
  overview: 'نظرة عامة',
  learningObjective: 'اهداف الدورة',
  learningJourney: 'رحلة التعلم',
  quiz: 'نشاط',
  videoLesson: 'درس فيديو',
  readingMaterial: 'مواد القراءة',
  startNow: 'ابدأ الآن',
  enrollNow: 'سجل الآن',
  lessons: 'الدروس',
  lesson: 'درس',
  rateThisCourse: 'قيم هذه الدورة',
  submitFeedback: 'إرسال ملاحظات',
  courseFeedback: 'التقييم',
  comment: 'تعليق',
  shareYourThoughts: 'شارك أفكارك',
  author: 'المؤلف',
  continue: 'استمر',
  description: 'الوصف',
  progress: 'تقدم',
  finish: 'إنهاء',
  yearsOfExperience: 'سنوات الخبرة',
  submitAnswer: 'اجب',
  no_courses: 'الدورات غير متاحة بعد!',
  mcqSubmitError: 'حدث خطأ في تقديم الإجابة، يرجى المحاولة مرة أخرى!',
  courseAccessErrorTitle: 'مطلوب الوصول إلى الدورة',
  courseAccessErrorDescription: 'هذا المحتوى المميز يتطلب الشراء. قم بفتح هذه الدورة للوصول إلى جميع المواد وابدأ التعلم اليوم',
  courseAccessErrorButton: 'تصفح الدورات الأخرى',
}
